
















































































































import Dialog from '@/calendesk/models/Dialog'
import dialogTypes from '@/components/dialogs/dialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'
import { errorNotification } from '@/lib/calendesk-js-library/tools/notification'
import mixins from 'vue-typed-mixins'
import DomainActions from '@/components/dialogs/components/domains/mixins/DomainActions'
import sharedConfirmDialogTypes from '@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes'
import PlanActions from '@/builder/mixins/PlanActions'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'
export default mixins(DomainActions, PlanActions).extend({
  name: 'UpdateSubdomainDialog',
  data () {
    return {
      errorMessage: null
    }
  },
  methods: {
    openDomainDialogHandle (): void {
      if (this.customDomainAvailable) {
        this.openDialog(new Dialog(true, dialogTypes.BUILDER_ADD_AND_UPDATE_YOUR_DOMAIN, '', dialogSize.LARGE))
      } else {
        this.openFeatureNotAvailableDialog()
      }
    },
    saveDomainHandle (): void {
      if (this.defaultDomain && this.defaultDomain.domain) {
        this.updateDomainHandle()
      } else {
        this.addDomainHandle()
      }
    },
    updateDomainHandle (): void {
      pushEvent('wbUpdateSubdomain')

      this.errorMessage = null
      this.loading = true
      this.$emit('loading', this.loading)
      const domain = cloneClassObject(this.defaultDomain)
      domain.domain = this.fullSubdomain
      this.updateDomain(domain)
        .then(() => { this.$emit('close') })
        .catch(error => {
          switch (error.response.status) {
            case 409:
              this.errorMessage = this.$trans('editor_domain_error_message')
              break
            default:
              errorNotification('error_occurred', error)
              break
          }
        })
        .finally(() => {
          this.loading = false
          this.$emit('loading', this.loading)
        })
    },
    addDomainHandle (): void {
      pushEvent('wbCreateSubdomain')

      this.errorMessage = null
      this.loading = true
      this.$emit('loading', this.loading)
      this.addDomain(this.fullSubdomain)
        .then(() => { this.$emit('close') })
        .catch(error => {
          switch (error.response.status) {
            case 409:
              this.errorMessage = this.$trans('editor_domain_error_message')
              break
            default:
              errorNotification('error_occurred', error)
              break
          }
        })
        .finally(() => {
          this.loading = false
          this.$emit('loading', this.loading)
        })
    },
    removeDomainHandle (): void {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        hideImportantInformation: true,
        confirmAction: () => {
          pushEvent('wbRemoveSubdomain')

          this.setCommonDialogLoader(true)
          this.removeDomain(this.defaultDomain.id)
            .catch((error: any) => {
              errorNotification('error_occurred', error)
            })
            .finally(() => {
              this.$emit('close')
              this.closeConfirmDialog()
              this.setCommonDialogLoader(false)
            })
        }
      })
    }
  }
})
